* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    font-family: var(--font-family);
    color: #1f1f1f;
    background-color: #fcfcf2;
}

a {
    color: unset;
    text-decoration: none;
}

.linear-gr {
    /* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(197, 218, 255, 1) 0%, rgba(20, 44, 87, 1) 100%); 
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(197, 218, 255, 1) 0%, rgba(20, 44, 87, 1) 100%);
    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(197, 218, 255, 1) 0%, rgba(20, 44, 87, 1) 100%);
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#142C57', endColorstr='#C5DAFF', GradientType=1 );
    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(197, 218, 255, 1) 0%, rgba(20, 44, 87, 1) 100%);
    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(197, 218, 255, 1) 0%, rgba(20, 44, 87, 1) 100%);
}

.text-gr {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mt-2 {
    margin-top: 2rem;
}

.section-padding {
    padding: 4rem 6rem;
}

.section-margin {
    margin: 4rem 6rem;
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-14 15:32:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
@media screen and (max-width: 780px) {
    .section-padding {
        padding: 4rem;
    }
    .section-margin {
        margin: 4rem;
    }   
}

@media screen and (max-width: 550px) {
    .section-padding {
        padding: 4rem 2rem;
    }
    .section-margin {
        margin: 4rem 2rem;
    }   
}

/* Navbar */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.navbar-links {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links-logo {
    margin-right: 2rem;
}

.navbar-links-logo img {
    max-width: 50px;
}

.navbar-links-container {
    display: flex;
}

.navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-links-container p,
.navbar-sign p,
.navbar-menu-container p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-sign a:nth-child(2),
.navbar-menu-container-links-sign a:nth-child(2) {
    padding: 0.5rem 1rem;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    background: #ff4820;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

.navbar-menu {
    display: none;
    margin-left: 1rem;
    position: relative;
}

.navbar-menu svg {
    cursor: pointer;
}

.navbar-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    padding: 2rem;
    color: #fff;
    background: var(--color-footer);
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
    z-index: 1000;
}

.navbar-menu-container p {
    margin: 1rem 0;
}

.navbar-menu-container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .navbar-links-container {
        display: none;
    }

    .navbar-menu {
        display: flex;
        margin-left: auto;
    }
}

@media screen and (max-width: 780px) {
    .navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 2rem;
    }

    .navbar-links-logo img {
        width: 100px;
    }

    .navbar-sign {
        display: none;
    }

    .navbar-menu-container {
        top: 20px;
    }

    .navbar-menu-container-links-sign {
        display: block;
    }
}

/*  HEADER */

.header {
    display: flex;
    margin: 0 0 2rem;
    border-top-right-radius: 90px;
    border-top-left-radius: 90px;
    border-top: solid 1px #bdbdb8;
}

.header-content {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.header-content h1 {
    font-weight: 800;
    font-size: 52px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-content p {
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: -0.04em;
    margin-top: 2rem;
}

.header-content-input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.header-content-input a {
    padding: 0.5rem 1rem;
    font-size: 18px;
    line-height: 25px;
    background: #ff4820;
    color: #fff;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

.header-image {
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: center;
    padding: 2rem 0.2rem;
    border-radius: 85px;
    /* ff 3.6+ */
    background:-moz-linear-gradient(328deg, rgb(252, 252, 242) 0%, rgb(192, 208, 238) 73%); 
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%);
    /* opera 11.10+ */
    background:-o-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%);
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FCFCF2', endColorstr='#C0D0EE', GradientType=1 );
    /* ie 10+ */
    background:-ms-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%);
    /* global 94%+ browsers support */
    background:linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%);
}

.header-image img {
    width: 80%;
    min-width: 100px;
    max-width: 300px;
}

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }
    
    .header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 780px) {
    .header h1 {
        font-size: 32px;
        line-height: 45px;
    }
    
    .header-content {
        margin: 0 0 3rem;
    }

    .header-image {
        border-radius: 45px;
    }
}

@media screen and (max-width: 550px) {
    .header {
        padding-top: unset !important;
        border-top-right-radius: 45px;
        border-top-left-radius: 45px;
    }
    .header h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .header-image img {
        max-width: 150px;
    }
}

/* SECTION */
.section-break {
    display: flex;
    justify-content: center;
    background-color: #f5efdc;
    border-top: solid 1.5px #bdbdb8;
    border-top-right-radius: 90px;
    border-top-left-radius: 90px;
}

.section-break h2 {
    font-weight: 600;
    font-size: 42px;
    line-height: 55px;
    letter-spacing: -0.04em;
    max-width: 700px;
    text-align: center;
}

@media screen and (max-width: 780px) {
    .section-break h2 {
        font-size: 32px;
        line-height: 50px;
    }

    .section-padding {
        padding: 2rem 2rem;
    }
}

@media screen and (max-width: 550px) {
    .section-break {
        border-top-right-radius: 45px;
        border-top-left-radius: 45px;
    }

    .section-break h2 {
        font-size: 28px;
        line-height: 40px;
    }

    .section-padding {
        padding: 1rem 2rem;
    }
}

/* PRODUCTS */
.products {
    border-top: solid 1px #bdbdb8;
}

.card {
    display: flex;
    padding: 0 0 2rem;
}

.card-gr {
    /* ff 3.6+ */
    background:-moz-linear-gradient(328deg, rgb(252, 252, 242) 0%, rgb(192, 208, 238) 73%) !important; 
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgb(192, 208, 238) 73%) !important;
    /* opera 11.10+ */
    background:-o-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%) !important;
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FCFCF2', endColorstr='#C0D0EE', GradientType=1 ) !important;
    /* ie 10+ */
    background:-ms-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%) !important;
    /* global 94%+ browsers support */
    background:linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%) !important;
}

.card-image {
    display: flex;
    flex: 0.4;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-width: 80%;
    padding: 2rem 2rem;
    border-radius: 85px;
    /* ff 3.6+ */
    background:-moz-linear-gradient(164deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%); 
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(164deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%);
    /* opera 11.10+ */
    background:-o-linear-gradient(164deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%);
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C0D0EE', endColorstr='#FCFCF2', GradientType=1 );
    /* ie 10+ */
    background:-ms-linear-gradient(164deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%);
    /* global 94%+ browsers support */
    background:linear-gradient(164deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%);
}

.card-image img {
   /*  min-width: 320px; */
    max-width: 100%;
}

.card-content {
    flex: 0.6;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 70%;
}

.card-content h2 {
    font-weight: 600;
    font-size: 42px;
    line-height: 55px;
    letter-spacing: -0.04em;
    max-width: 700px;
}

.card-content p {
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: -0.04em;
    margin-top: 2rem;
}

.card-content-link a {
    display: flex;
    align-items: center;
    margin: 2rem 0;
}

.card-content-link span {
    font-weight: 800;
}

.card-content-link span:hover {
    margin-right: 0.25rem;
    transition: 0.2s;
}

.card-content-link svg {
    margin-left: 0.2rem;
}

.card-content-link a svg:hover {
    font-size: 15px;
    transition: 0.1s;
}

.product-cbt .card-content {
    margin-left: 5rem;
}

.product-lms .card-image {
    order: 2;
    /* ff 3.6+ */
    background:-moz-linear-gradient(328deg, rgb(252, 252, 242) 0%, rgb(192, 208, 238) 73%) !important; 
    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgb(192, 208, 238) 73%) !important;
    /* opera 11.10+ */
    background:-o-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%) !important;
    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FCFCF2', endColorstr='#C0D0EE', GradientType=1 ) !important;
    /* ie 10+ */
    background:-ms-linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%) !important;
    /* global 94%+ browsers support */
    background:linear-gradient(328deg, rgba(252, 252, 242, 1) 0%, rgba(192, 208, 238, 1) 73%) !important;
}

.product-lms .card-content {
    margin-right: 5rem; 
}

.product-sms .card-content {
    margin-left: 5rem;
}

@media screen and (max-width: 1050px) {
    .card {
        flex-direction: column;
        align-items: center;
    }
    
    .card-content {
        margin: 2rem 0 0;
    }

    .card-content p {
        max-width: 85%;
    }

    .product-cbt .card-content {
        margin-left: unset;
    }

    .product-lms .card-content {
        margin-right: unset;
        order: 2;
    }

    .product-sms .card-content {
        margin-left: unset;
    }
}

@media screen and (max-width: 780px) {
    .card h2 {
        font-size: 32px;
        line-height: 40px;
    }
    
    .card-image {
        max-width: 80%;
    }
    
    .card-content {
        margin: 2rem 0 0;
        max-width: 80%;
    }

    .card-content p {
        max-width: 90%;
        margin: 1rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .card-image {
        border-radius: 45px;
    }

    .card h2 {
        font-size: 25px;
        line-height: 35px;
    }

    .card-content p {
        max-width: unset;
        font-size: 20px;
        line-height: 30px;
    }

    .card-content-link span:hover {
        margin-right: unset;
    }
}

/* ARTICLES */
.articles {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem 4rem;
    margin: 0 0 6rem;
    background-color: #f5efdc;
    color: #142c57;
    border-bottom: solid 1px #bdbdb8;
    border-top: solid 1.5px #bdbdb8;
    border-top-left-radius: 90px;
    border-top-right-radius: 90px;
}

.articles-title {
    align-self: center;
    font-weight: 600;
    font-size: 42px;
    line-height: 55px;
    letter-spacing: -0.04em;
}

.slick-slider {
    align-self: center;
    width: 300px;
    margin: 2rem 0 0;
    border-radius: 30px;
}

.slick-slider img {
    border-radius: 30px;
}

.slick-prev:before,
.slick-next:before {
    color: #142c57;
    opacity: 1;
}

.slick-prev.slick-disabled:hover,
.slick-next.slick-disabled:hover {
    cursor: unset;
}

.slick-prev.slick-disabled:hover:before,
.slick-next.slick-disabled:hover:before {
    font-size: 20px;
    transition: 0.1s;
}

.slick-prev:hover:before,
.slick-next:hover:before
{
    font-size: 19px;
    transition: 0.1s;
}

.slick-dots li button:before
{
    color: #142c57;
    transition: 0.2s;
}

.slick-dots li.slick-active button:before
{
    color: #142c57;
}

@media screen and (max-width: 780px) {
    .articles-title {
        font-size: 32px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .articles {
        padding: 2rem 2rem 4rem;
        border-top-right-radius: 45px;
        border-top-left-radius: 45px;
    }

    .articles-title {
        font-size: 25px;
        line-height: 40px;
    }

    .slick-slider {
        width: 80%;
        max-width: 250px;
    }
}

/* FOOTER */
.footer {
    display: flex;
    flex-direction: column;
    align-content: center;
}

.footer-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 20px; /*top right&left bottom*/                
}

.footer-nav ul{
    display: flex;                
    flex-wrap: wrap;
}

.footer-nav ul li {
    padding: 0 10px;
    margin-bottom: 5px;
    list-style: none;
    cursor: pointer;
    color: #142c57;
    border-left: solid 1px #c5c3c3;
}

.footer-nav ul li:first-child {
    border-left: none;
    padding-left: 0;
}

.footer-nav ul li:last-child {
    padding-right: 0;
}

.copy {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.8rem 1rem;
    background-color: #f5efdc;
    border-top: solid #bdbdb8 1px;
}

.copy span {
    font-size: 0.8rem;
    letter-spacing: 0.06em;
}

@media screen and (max-width: 550px) {
    .footer-nav ul {
        flex-direction: column;
        align-self:flex-start;
        max-height: 100px;
    }

    .footer-nav ul li {
        padding: 2px 30px;
        list-style: none;
        cursor: pointer;
        color: #142c57;
        border-left: none;
    }
    
    .footer-nav ul li:first-child {
        border-left: none;
        padding-left: 30px;
    }
}

@media screen and (max-width: 300px) {
    .footer-nav ul {
        max-height: none;
    }
}
